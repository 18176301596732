import React from "react";
import { Trans, useI18n } from "@bookingcom/lingojs-react";
import { Stack, Text, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { UIOrder } from "@flights/types";
import { isSirfApplied } from "../../../store/selectors/sirf";
import SirfWebCheckinReminder from "../SirfWebCheckinReminder";

interface CheckinHowToProps {
  order: UIOrder;
  isCheckInExperiment?: boolean;
}

export const CheckinHowTo: React.FC<CheckinHowToProps> = ({ order, isCheckInExperiment }) => {
  const i18n = useI18n();
  const appliedSubsidizedFares = order.airOrder.appliedSubsidizedFares || [];

  return (
    <Stack gap={2}>
      <Title
        variant="headline_3"
        title={
          isCheckInExperiment
            ? i18n.trans(t("flights_pb_checkin_when_how_title"))
            : i18n.trans(t("flights_pb_checkin_howto_title"))
        }
      />
      <Text variant="body_2">
        1.{" "}
        {isCheckInExperiment ? (
          <Trans
            tag={"flights_pb_checkin_when_how_body_1"}
            variables={{ start_bold: "<0>", end_bold: "</0>" }}
            components={[<strong key={0} />]}
          />
        ) : (
          <Trans
            tag={"flights_pb_checkin_howto_body_1"}
            variables={{ start_bold: "<0>", end_bold: "</0>" }}
            components={[<strong key={0} />]}
          />
        )}
      </Text>
      <Text variant="body_2">
        2.{" "}
        {isCheckInExperiment ? (
          <Trans
            tag={"flights_pb_checkin_when_how_body_2"}
            variables={{ start_bold: "<0>", end_bold: "</0>" }}
            components={[<strong key={0} />]}
          />
        ) : (
          <Trans
            tag={"flights_pb_checkin_howto_body_2"}
            variables={{ start_bold: "<0>", end_bold: "</0>" }}
            components={[<strong key={0} />]}
          />
        )}
      </Text>
      <Text variant="body_2">
        3.{" "}
        <Trans
          tag={"flights_pb_checkin_howto_body_3"}
          variables={{ start_bold: "<0>", end_bold: "</0>" }}
          components={[<strong key={0} />]}
        />
      </Text>
      {isSirfApplied(appliedSubsidizedFares) && <SirfWebCheckinReminder order={order} />}
    </Stack>
  );
};
