import React, { useState } from "react";
import useGlobalContext from "../../../hooks/useGlobalContext";
// eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
import { useActions, useStore } from "../../../store";
import { actions as OrderActions } from "../../../store/order/actions";
import { UIOrderStatus } from "@flights/types";
import { MpStoreState } from "@bookingcom/mp-flights";

export type ConfirmationControllerData = ReturnType<typeof useConfirmation>;

interface Props {
  children: (data: ConfirmationControllerData) => React.ReactElement;
}

export const useTestConfirmation = () => {
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const store = useStore();
  const { isInternal } = useGlobalContext();
  // eslint-disable-next-line import/no-deprecated -- this line was auto generated, hence fix the issue timely
  const orderActions = useActions(OrderActions);
  const { order, addOnOrder } = store.order;
  if (!order) {
    throw new Error("Missing order data");
  }

  const queryParams = new URLSearchParams(location.search);
  if (!order.isTest && isInternal && Number(queryParams.get("test")) === 1) {
    console.warn("Overriding order"); // eslint-disable-line
    // If the page is accessed internally and there's `test` parameter present in the URL, then
    // it will override the order in the local store and change it to the test one.
    const confirmationType = queryParams.get("type");
    orderActions.fetchSuccess({
      ...order,
      isTest: true,
      ...(confirmationType && { orderStatus: confirmationType.toUpperCase() as UIOrderStatus })
    });
    if (addOnOrder) {
      const addOnConfirmationType = queryParams.get("addOnType");
      orderActions.setAddOnOrder({
        ...addOnOrder,
        isTest: true,
        ...(addOnConfirmationType && { orderStatus: addOnConfirmationType.toUpperCase() as UIOrderStatus })
      });
    }
  }
};

const useConfirmation = function () {
  useTestConfirmation();
  const [mpState, setMpState] = useState<MpStoreState>({ isLoading: false, multiProducts: [] });
  return { mpState, setMpState };
};

const ConfirmationController: React.FunctionComponent<Props> = (props) => {
  const confirmationData = useConfirmation();

  return props.children(confirmationData);
};

export default ConfirmationController;
