import React from "react";
import { Image, Stack, Text, useTheme } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import Frame from "components/elements/Frame";
import { BLACK_FRIDAY_BG_COLOR, BLACK_FRIDAY_IMAGE_URL } from "../../../constants";

const BlackFridayBanner = ({}) => {
  const i18n = useI18n();
  const theme = useTheme();

  return (
    <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        attributes={{ style: { height: 300, backgroundColor: BLACK_FRIDAY_BG_COLOR, padding: theme.units.spacing_4x } }}
      >
        <Stack.Item grow>
          <Text color="white" variant="headline_3" attributes={{ style: { maxWidth: 160 } }}>
            {i18n.trans(t("deals_blackfriday24_mdot_index_hero_row_mile1_title_flights"))}
          </Text>
        </Stack.Item>
        <Image src={BLACK_FRIDAY_IMAGE_URL} width="160px"></Image>
      </Stack>
    </Frame>
  );
};

export default BlackFridayBanner;
