/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { Profiler, useContext, useEffect, useRef } from "react";
import { Stack, Text, Title, useTheme } from "@bookingcom/bui-react";
import { SearchboxController } from "@bookingcom/flights-searchbox";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import CheapFlights from "components/elements/CheapFlights";
import FrequentlyAskedQuestionsDesktop from "components/elements/FrequentlyAskedQuestions/FrequentlyAskedQuestions.desktop";
import TrendingDestinations from "components/elements/TrendingDestinations";
import UspBlock from "components/elements/UspBlock";
import { trackMetaLandingsCustomGoalsV2 } from "utils/experiments/track-meta-landings-v2";
import { SearchDesktop } from "../app/routes";
import { AppShellDesktop } from "../components/elements/AppShell";
import Container from "../components/elements/Container";
import useClientMetrics from "../hooks/useClientMetrics";
import useGlobalContext from "../hooks/useGlobalContext";
import useLocaleContext from "../hooks/useLocaleContext";
import useSearchbox from "../hooks/useSearchbox";
import useTrackPageLoad from "../hooks/useTrackPageLoad";
import { trackExperiment, trackExperimentStage, trackGoal } from "../utils/et";
import { PageHeader } from "./Home";
import NoJS from "./NoJS";
import { EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK } from "constants/experiments";
import { SearchBoxHorizontalDefault } from "@bookingcom/flights-searchbox";
import StaticContext from "app/StaticContext/StaticContext";
import HomeRecentSearch from "components/elements/HomeRecentSearch";
import Frame from "components/elements/Frame";
import { HomeSearchHeaderDesktop } from "components/elements/HomeSearchHeader";
import {
  RemoteAppleCardIndex,
  RemoteGeniusLaunchSheet,
  RemoteGeniusLoyalty,
  RemoteSEOInterlinking
} from "components/remote";
import { IndexCampaignBanner } from "components/elements/IndexCampaignBanner";
import ThemeBasedDestinations from "components/elements/ThemeBasedDestinations";
import { isOfMetaOrigin, isOfPPCOrigin } from "../utils/marketing-url-params";
import NoSSR from "../app/NoSSR";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import { GeniusAirlinesBanner } from "components/elements/GeniusAirlinesBanner";
import { trackGeniusAirlinesIndexCampaign } from "utils/experiments/customer-aquisition/flights_web_cat_genius_airlines_campaign_index";
import useProfiler from "hooks/useProfiler";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import CrowdstrikeOutage from "components/elements/CrowdstrikeOutage";
import { useTrackPpcWithoutSearchHistory } from "utils/experiments/customer-aquisition/flights_web_cat_ppc_without_search_history_aa";
import { GeniusFlightsHomeBanner } from "components/elements/FlightCampaignTCBanner/GeniusHomeBanner";
import { stage, trackWithDefaultStage } from "utils/etV2";
import { FlightsInterlinkingDesktop } from "components/elements/FlightsInterlinking";
import flights_web_cat_theme_based_destinations_www from "utils/experiments/customer-aquisition/flights_web_cat_theme_based_destinations_www";
import useIsInViewport from "hooks/useIsInViewport";
import EsimBanner from "ancillaries/components/Esim/EsimBanner";
import isEmpty from "lodash/isEmpty";
import { useSelector } from "react-redux";
import { getEsim } from "store/esim/selectors";
import flights_ace_web_esim_banner from "utils/experiments/ace/flights_ace_web_esim_banner";
import { useEsimGetLink } from "ancillaries/hooks/Esim/useEsimFetch";
import { BLACK_FRIDAY_EXCLUDED_COUNTRIES } from "../constants";

const HomeDesktop = () => {
  const staticContext = useContext(StaticContext);
  const { ipCountry = "" } = useGlobalContext();
  const theme = useTheme();
  const i18n = useI18n();
  const trackContextEventV2 = useTrackPageLoad("index");
  const { userAgent, searchHistory, features } = useGlobalContext();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const { trackRenderTime } = useClientMetrics();
  const trackLandingPageEvents = useLandingPageEvents();
  const { isMeta } = useProfiler();
  const { trackFlyAnywhereWww } = useTrackFlyAnywhere();
  const interlinkingRef = useRef<HTMLDivElement>(null);
  const isInterlinkingVisible = useIsInViewport(interlinkingRef);
  /** start - flights_ace_web_esim_banner */
  const esimAvailable = !isEmpty(useSelector(getEsim));
  const showEsimBanner = esimAvailable && !!flights_ace_web_esim_banner.trackWithDefaultStage();
  /** end - flights_ace_web_esim_banner */

  if (process.env.BUILD_TARGET === "server" && staticContext.set) {
    const { getInitialStateDesktop } = require("../server/screens/home");
    staticContext.set({ getInitialState: getInitialStateDesktop });
  }

  /** start - flights_ace_web_esim_banner */
  useEsimGetLink();
  /** end - flights_ace_web_esim_banner */

  useEffect(() => {
    SearchDesktop.preload();
    trackContextEventV2();
    trackGoal("flights_home_page_view");
    trackLandingPageEvents("page_view", "homepage");

    // track meta users going to index page
    trackMetaLandingsCustomGoalsV2(2, "desktop");
    trackExperimentStage("flights_web_a11y_searchbox", 1); // stage 1 - Desktop + Mdot Index + Search results - a11y searchbox
    trackExperimentStage("flights_web_a11y_searchbox", 2); // stage 2 - Desktop - Index - a11y searchbox
    // bb_flights_prompt_travel_purpose
    trackExperimentStage(EXP_FLIGHTS_IS_TRAVELLING_FOR_WORK, 1);

    flights_web_cat_theme_based_destinations_www.stages.main();
    stage("flights_web_cat_interlinking_replace_rc", 2); // desktop
    stage("flights_web_cat_black_friday_hero", 2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isInterlinkingVisible) {
      stage("flights_web_cat_interlinking_replace_rc", 4);
    }
  }, [isInterlinkingVisible]);

  const { canShowOnDesktop } = useTrackPpcWithoutSearchHistory(false);

  useEffect(() => {
    if (!!searchHistory?.length) return;
    trackExperimentStage("flights_web_ddot_sb_bg_color", 1);
    trackExperimentStage("flights_web_ddot_sb_bg_color", 2);
    trackExperimentStage("flights_web_ddot_sb_bg_color", isMeta() ? 5 : 4);
  }, [searchHistory, isMeta]);

  const hideSBBag = !!searchHistory?.length || !!trackExperiment("flights_web_ddot_sb_bg_color");
  const isBlackFridayExp =
    !isOfPPCOrigin() &&
    !isOfMetaOrigin() &&
    !BLACK_FRIDAY_EXCLUDED_COUNTRIES.includes(ipCountry) &&
    trackWithDefaultStage("flights_web_cat_black_friday_hero", 1);

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShellDesktop>
        <Frame
          alignItems="center"
          direction="column"
          justifyContent="center"
          grow={1}
          attributes={{ style: { width: "100%" } }}
        >
          {isBlackFridayExp ? (
            <HomeSearchHeaderDesktop />
          ) : (
            <Frame
              attributes={{
                style: {
                  backgroundColor:
                    userAgent.isWebview || hideSBBag
                      ? theme.colors.color_background_base
                      : theme.colors.color_background_base_alt,
                  width: "100%"
                }
              }}
            >
              <Container
                style={{
                  margin: `0 auto ${hideSBBag ? theme.units.spacing_12x : theme.units.spacing_16x}`,
                  alignItems: "start"
                }}
              >
                <NoJS />
                <Frame mt={6}>
                  <Stack gap={2}>
                    <Stack gap={2}>
                      <Text variant="headline_1" data-testid="home_header" tagName="h1">
                        {i18n.trans(t("flights_index_header_compare_book_ease"))}
                      </Text>

                      <Text variant="body_1">{i18n.trans(t("flights_index_header_discover_next_dream"))}</Text>
                    </Stack>

                    {/*
                   SearcboxController Context available here from the searchbox package
                   (controllerContext) => <SearchboxDesktop shouldScrollOnDatePickerOpen={true}></SearchboxDesktop>
                */}
                    <SearchboxController
                      i18n={i18n}
                      {...searchBoxProps}
                      isRTL={isRTL}
                      trackFlyAnywhereWww={() => trackFlyAnywhereWww()}
                      onSearch={(searchParams) => {
                        trackLandingPageEvents("click", "searchbox");
                        trackGeniusAirlinesIndexCampaign.goals.search_button_click();
                        searchBoxProps.onSearch(searchParams);
                      }}
                    >
                      {() => <SearchBoxHorizontalDefault />}
                    </SearchboxController>
                    <CrowdstrikeOutage />
                  </Stack>
                </Frame>
              </Container>
            </Frame>
          )}

          {canShowOnDesktop && (
            <Frame attributes={{ style: { width: "100%" } }} mb={0}>
              <UspBlock
                hideBackground={!!trackWithDefaultStage("flights_web_cat_black_friday_hero", 1)}
                isPPCVariant={true}
              />
            </Frame>
          )}

          <GeniusAirlinesBanner />

          <NoSSR>
            <HomeRecentSearch />
          </NoSSR>

          <GeniusFlightsHomeBanner />

          <IndexCampaignBanner />

          {!!flights_web_cat_theme_based_destinations_www.trackWithDefaultStage() ? (
            <>
              <ThemeBasedDestinations isMobile={false} />
              <RemoteAppleCardIndex />
            </>
          ) : (
            <>
              <CheapFlights isMobile={false} />
              <RemoteAppleCardIndex />
              <TrendingDestinations isMobile={false} />
            </>
          )}

          {!!features.FLIGHTS_WEB_CAT_GENIUS_LOYALTY_RC ? <RemoteGeniusLoyalty /> : undefined}

          {showEsimBanner && (
            <Container style={{ margin: `0 auto ${theme.units.spacing_16x}`, alignItems: "start" }}>
              <Title title={i18n.trans(t("flights_genius_section_header"))} variant="headline_2" titleTagName="h2" />
              <EsimBanner isPostBooking />
            </Container>
          )}

          {!canShowOnDesktop && (
            <Frame attributes={{ style: { width: "100%" } }} mt={8} mb={8}>
              <UspBlock />
            </Frame>
          )}

          {trackWithDefaultStage("flights_web_cat_interlinking_replace_rc", 1) ? (
            <FlightsInterlinkingDesktop />
          ) : (
            <RemoteSEOInterlinking isMobile={false} />
          )}
          <div ref={interlinkingRef}></div>

          <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>
            <FrequentlyAskedQuestionsDesktop />
          </Container>
        </Frame>
      </AppShellDesktop>
      {!!features.FLIGHTS_WEB_CAT_GENIUS_LAUNCH_SHEET_RC ? <RemoteGeniusLaunchSheet /> : undefined}
    </Profiler>
  );
};

export default HomeDesktop;
