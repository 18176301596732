import React, { useRef } from "react";
import { Card, Title, Text, Carousel } from "@bookingcom/bui-react";
import { FaqItem, getFaqData } from "./FrequentlyAskedQuestionsData";
import { I18nChildContext, t, useI18n } from "@bookingcom/lingojs-react";
import styles from "./FrequentlyAskedQuestions.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";

const groupItemsIntoColumns = (faqList: FaqItem[]): FaqItem[][] => {
  const faqItemsByColumn: FaqItem[][] = [];
  for (let i = 0; i < faqList.length; i += 2) {
    const columnItems = [faqList[i]];
    if (i + 1 < faqList.length) {
      columnItems.push(faqList[i + 1]);
    }
    faqItemsByColumn.push(columnItems);
  }
  return faqItemsByColumn;
};

/**
 * FAQ block on Flights index page on Mobile web.
 */
const FrequentlyAskedQuestions: React.FunctionComponent = () => {
  const i18n = useI18n() as I18nChildContext;

  const faqList = getFaqData(i18n);
  const faqItemsByColumn = groupItemsIntoColumns(faqList);
  const scrollRef = useRef<HTMLDivElement>(null);

  useTrackLandingPageViewport(scrollRef, "faq");

  const renderColumn = (faqColumnItems: FaqItem[], index: number) => {
    return (
      <div key={`faq-${index}`} className={styles.faqCardContainer}>
        {faqColumnItems.map((faq, index) => (
          <div key={index} itemScope itemProp="mainEntity" itemType="https://schema.org/Question">
            <Card variant="neutral" className={styles.faqCard}>
              <Title
                className={styles.question}
                variant="strong_1"
                title={faq.question}
                attributes={{ itemProp: "name" }}
              />
              <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                <Text attributes={{ itemProp: "text" }} variant="body_2">
                  {faq.answer}
                </Text>
              </div>
            </Card>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div ref={scrollRef}>
      <Title
        title={i18n.trans(t("seo_flights_new_index_lp_faq_header"))}
        variant="headline_2"
        className={styles.title}
        titleTagName="h2"
      />
      <div itemScope itemType="https://schema.org/FAQPage">
        <Carousel
          nextButtonAriaLabel={i18n.trans(t("a11y_flights_faq_carousel_next_button_aria_label"))}
          previousButtonAriaLabel={i18n.trans(t("a11y_flights_faq_carousel_previous_button_aria_label"))}
        >
          {faqItemsByColumn.map((item, index) => renderColumn(item, index))}
        </Carousel>
      </div>
    </div>
  );
};
export default FrequentlyAskedQuestions;
