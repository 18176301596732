import useHomeRecentSearch from "components/elements/HomeRecentSearch/useHomeRecentSearch.controller";
import { useEffect } from "react";
import { trackExperiment, trackExperimentStage } from "utils/et";
import { isOfPPCOrigin } from "utils/marketing-url-params";

export function useTrackPpcWithoutSearchHistory(isMobile: boolean) {
  const { searchHistoryList } = useHomeRecentSearch();

  // Recent search list on mdot is stopped
  const noSearchHistoryOnMdot =
    !trackExperiment("flights_web_mdot_home_recent_search_carousel") ||
    (!!trackExperiment("flights_web_mdot_home_recent_search_carousel") && searchHistoryList.length === 0);

  const eligibleForDesktop = isOfPPCOrigin() && !isMobile && searchHistoryList.length === 0;
  const eligibleForMdot = isOfPPCOrigin() && isMobile && noSearchHistoryOnMdot;

  useEffect(() => {
    // Recent search on ddot is full on
    if (eligibleForDesktop) {
      // AA experiment
      trackExperimentStage("flights_web_cat_ppc_without_search_history_aa", 1); // all eligible ppc
      trackExperimentStage("flights_web_cat_ppc_without_search_history_aa", 2); // eligible ppc desktop

      // AB experiment
      trackExperimentStage("flights_web_cat_ppc_without_search_history", 1); // all eligible ppc
      trackExperimentStage("flights_web_cat_ppc_without_search_history", 2); // eligible ppc mdot
    }

    if (eligibleForMdot) {
      // AA experiment
      trackExperimentStage("flights_web_cat_ppc_without_search_history_aa", 1); // all eligible ppc
      trackExperimentStage("flights_web_cat_ppc_without_search_history_aa", 3); // eligible ppc mdot

      // AB experiment
      trackExperimentStage("flights_web_cat_ppc_without_search_history", 1); // all eligible ppc
      trackExperimentStage("flights_web_cat_ppc_without_search_history", 3); // eligible ppc mdot
    }
  }, [eligibleForDesktop, eligibleForMdot]);

  return {
    canShowOnDesktop: eligibleForDesktop && !!trackExperiment("flights_web_cat_ppc_without_search_history"),
    canShowOnMdot: eligibleForMdot && !!trackExperiment("flights_web_cat_ppc_without_search_history")
  };
}
