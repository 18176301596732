import React from "react";

import { useI18n } from "@bookingcom/lingojs-react";
import { Icon, Stack, Text, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { WarningIcon } from "@bookingcom/bui-assets-react/streamline/index.js";

import { SPACER_PADDED } from "@flights/constants";
import { UIFlightSegment } from "@flights/types";
import { useFormatDateTime } from "@bookingcom/flights-core";

interface CheckinSegmentHeaderProps {
  segment: UIFlightSegment;
  showSubHeader?: boolean;
}
export const CheckinSegmentHeader: React.FC<CheckinSegmentHeaderProps> = ({ segment, showSubHeader }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);

  return (
    <Stack gap={4}>
      <Stack gap={0}>
        <Title
          variant="headline_3"
          title={i18n.trans(
            t("flights_pb_checkin_route_title", {
              variables: {
                origin_city: segment.departureAirport.cityName || "",
                origin_airport_code: segment.departureAirport.code,
                destination_city: segment.arrivalAirport.cityName || "",
                destination_airport_code: segment.arrivalAirport.code
              }
            })
          )}
        />
        {showSubHeader && (
          <Text variant="body_1" color="neutral_alt">
            {i18n.trans(
              t("flights_pb_checkin_route_itinerary", {
                variables: {
                  airline_name: segment.legs[0].carriersData[0].name,
                  departure_date: [
                    formats.flightDateWeekday(segment.departureTime),
                    formats.flightTime(segment.departureTime)
                  ].join(SPACER_PADDED)
                }
              })
            )}
          </Text>
        )}
      </Stack>
      {segment.isVirtualInterlining && (
        <Stack direction="row" gap={2} alignItems="center">
          <Icon size="small" color="callout" svg={WarningIcon} />
          <Stack gap={0}>
            <Text variant="strong_2" color="callout">
              {i18n.trans(t("flights_pb_checkin_vi_header"))}
            </Text>
            <Text color="callout" variant="body_2">
              {i18n.trans(t("flights_pb_checkin_vi_subhead"))}
            </Text>
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};
