import React, { Profiler } from "react";
import { useParams } from "react-router-dom";
import { AppShellDesktop } from "components/elements/AppShell";
import ConfirmationInnerLoader from "components/elements/ConfirmationInnerLoader";
import Container from "components/elements/Container";
import { assert } from "utils/assert";
import { PageHeader } from "./Home";
import useClientMetrics from "../hooks/useClientMetrics";
import { WebCheckinInner } from "../components/elements/WebCheckinInner";
import styles from "./WebCheckin.desktop.module.css";
import NoSSR from "../app/NoSSR";

function Webcheckin() {
  const params = useParams<{ orderToken: string }>();
  const { trackRenderTime } = useClientMetrics();
  assert(params?.orderToken, "Missing orderToken parameter");

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShellDesktop className={styles.appShell}>
        <NoSSR>
          <Container pr={4} pl={4}>
            <ConfirmationInnerLoader token={params?.orderToken} cache={true}>
              <WebCheckinInner />
            </ConfirmationInnerLoader>
          </Container>
        </NoSSR>
      </AppShellDesktop>
    </Profiler>
  );
}

export default Webcheckin;
