import React, { Profiler } from "react";
import { useParams } from "react-router-dom";
import AppShell from "components/elements/AppShell";
import ConfirmationInnerLoader from "components/elements/ConfirmationInnerLoader";
import { assert } from "utils/assert";
import { PageHeader } from "./Home";
import useClientMetrics from "../hooks/useClientMetrics";
import { WebCheckinInner } from "../components/elements/WebCheckinInner";
import NoSSR from "../app/NoSSR";

function Webcheckin() {
  const params = useParams<{ orderToken: string }>();
  const { trackRenderTime } = useClientMetrics();

  assert(params?.orderToken, "Missing orderToken parameter");

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShell noPadding>
        <NoSSR>
          <ConfirmationInnerLoader token={params?.orderToken} cache={true}>
            <WebCheckinInner />
          </ConfirmationInnerLoader>
        </NoSSR>
      </AppShell>
    </Profiler>
  );
}

export default Webcheckin;
