import React from "react";

import { Breadcrumbs, Stack } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { UICarrierCheckinInfo, UIFlightSegment, UILeg, UIOrder } from "@flights/types";
import useCreateUrl from "hooks/useCreateUrl";
import { getAirlineReferences } from "store/selectors/airOrder";

import ConfirmationController from "../ConfirmationController";
import { CheckinHeader } from "./CheckinHeader";
import { CheckinHowTo } from "./CheckinHowTo";
import { CheckinSegmentHeader } from "./CheckinSegmentHeader";
import Wrapper from "../../atoms/Wrapper";
import { CheckInInfoCard } from "./CheckinInfoCard";

interface WebCheckinInnerProps {
  order: UIOrder;
  segmentIndex: number;
  segment: UIFlightSegment;
  legs: UILeg[];
  checkinInfoForLeg: UICarrierCheckinInfo;
}

export const WebCheckinInnerV2: React.FC<WebCheckinInnerProps> = ({
  order,
  segmentIndex,
  segment,
  legs,
  checkinInfoForLeg
}) => {
  const i18n = useI18n();
  const { createUrl } = useCreateUrl();
  const airlineReferences = getAirlineReferences(order, segmentIndex);

  return (
    <ConfirmationController>
      {() => (
        <>
          <Wrapper>
            <Stack gap={4}>
              <Breadcrumbs
                back
                items={[
                  {
                    text: i18n.trans(t("flights_pb_checkin_navigation")),
                    href: createUrl(`/booking/order-details/${order?.orderToken}`)
                  }
                ]}
              />
              <CheckinHeader segment={segment} />
            </Stack>
          </Wrapper>
          <Wrapper>
            <CheckinHowTo isCheckInExperiment order={order} />
          </Wrapper>
          <Wrapper>
            <Stack gap={4}>
              <CheckinSegmentHeader segment={segment} showSubHeader />
              {airlineReferences.length === 1 ? (
                <CheckInInfoCard
                  leg={legs[0]}
                  segment={segment}
                  segmentIndex={segmentIndex}
                  legIndex={0}
                  openCheckin={checkinInfoForLeg.isOnlineCheckinOpen}
                  checkInInfo={checkinInfoForLeg}
                  showVuelingAlert
                  isCheckinExperiment
                />
              ) : (
                legs.map((leg, legIndex) => (
                  <CheckInInfoCard
                    key={legIndex}
                    leg={leg}
                    segment={segment}
                    segmentIndex={segmentIndex}
                    legIndex={legIndex}
                    openCheckin={checkinInfoForLeg.isOnlineCheckinOpen}
                    checkInInfo={checkinInfoForLeg}
                    showHeader={legs.length > 1}
                    showVuelingAlert
                    isCheckinExperiment
                  />
                ))
              )}
            </Stack>
          </Wrapper>
        </>
      )}
    </ConfirmationController>
  );
};
