import React from "react";
import { useSelector } from "react-redux";
import { getIndexCampaigns } from "store/indexCampaigns/selectors";
import Frame from "components/elements/Frame/Frame";
import Container from "components/elements/Container";
import { FlightCampaignTCBanner } from "components/elements/FlightCampaignTCBanner";
import {
  flights_web_cat_genius_campaign_www,
  useTrackGeniusIndexStages
} from "utils/experiments/customer-aquisition/flights_web_cat_genius_campaign_www";

export function GeniusFlightsHomeBanner() {
  const { genius: geniusCampaign } = useSelector(getIndexCampaigns);
  const eligibleForExperiment = !!geniusCampaign;

  useTrackGeniusIndexStages(eligibleForExperiment);

  if (!geniusCampaign || !flights_web_cat_genius_campaign_www.trackWithDefaultStage()) {
    return null;
  }

  return (
    <Container>
      <Frame mt={6} mb={8}>
        <FlightCampaignTCBanner
          campaignBanner={geniusCampaign}
          onCtaClick={flights_web_cat_genius_campaign_www.goals.open_modal_1}
        />
      </Frame>
    </Container>
  );
}
