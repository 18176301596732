import React from "react";
import styles from "./Sidebar.module.css";
import { mcn } from "utils/mergeClassnames";

type Props = {
  transparent?: Boolean;
};

function Sidebar(props: React.PropsWithChildren<Props>) {
  const { transparent } = props;
  return <div className={mcn(transparent && styles.transparent)}>{props.children}</div>;
}

export default Sidebar;
