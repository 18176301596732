import { UIOrder } from "@flights/types";

export const getAirlineReferences = (order: UIOrder, segmentIndex: number, legIndex?: number) => {
  const refs: string[] = [];

  order.airOrder.airlineReferencesByLeg?.forEach((airlineRef) => {
    airlineRef.legIdentifier.forEach((x) => {
      if (legIndex === undefined) {
        if (x.segmentIndex === segmentIndex) {
          refs.push(airlineRef.reference);
        }
      } else {
        if (x.segmentIndex === segmentIndex && x.legIndex === legIndex) {
          refs.push(airlineRef.reference);
        }
      }
    });
  });

  // Only return the unique values
  return [...new Set(refs)];
};
