import React from "react";
import Sidebar from "../Sidebar";
import styles from "./TwoColumnLayout.module.css";

interface Props {
  children: React.ReactElement;
  sidebar?: React.ReactElement;
  transparent?: boolean;
}

function TwoColumnLayout(props: Props) {
  const { children, sidebar, transparent } = props;

  return (
    <section className={styles.root}>
      <div className={styles.content}>{children}</div>
      <div className={styles.sidebar}>
        <Sidebar transparent={transparent}>{sidebar}</Sidebar>
      </div>
    </section>
  );
}

export default TwoColumnLayout;
