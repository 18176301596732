import React from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { CopyIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Icon, Link, Text, useToast } from "@bookingcom/bui-react";

import Frame from "../Frame";
import styles from "./CheckinInfoCardItem.module.css";

interface CheckinInfoCardItemProps {
  text: string;
  arialLabel: string;
  onCopySuccessText: string;
}

export const CheckinInfoCardItem: React.FC<CheckinInfoCardItemProps> = ({ text, arialLabel, onCopySuccessText }) => {
  const { show: showToast } = useToast();

  return (
    <Frame direction="row" alignItems="center">
      <Text variant="strong_2" className={styles.text}>
        {text}
      </Text>
      <CopyToClipboard text={text}>
        <Link
          attributes={{ "aria-label": arialLabel }}
          text=""
          className={styles.clipboardButton}
          onClick={() => showToast({ text: onCopySuccessText })}
        >
          <Icon svg={CopyIcon} size="small" color="action" />
        </Link>
      </CopyToClipboard>
    </Frame>
  );
};
