/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React from "react";
import { AspectRatio, Card, Grid, Stack, Text } from "@bookingcom/bui-react";
import { useI18n, t } from "@bookingcom/lingojs-react";
import useUserAgent from "hooks/useUserAgent";
import useCreateUrl from "hooks/useCreateUrl";
import Container from "components/elements/Container";
import useGlobalContext from "hooks/useGlobalContext";
import styles from "./HomeRecentSearch.module.css";
import useHomeRecentSearch from "./useHomeRecentSearch.controller";

export default function HomeRecentSearch() {
  const { apiPrefix } = useGlobalContext();
  const i18n = useI18n();
  const { isMobile } = useUserAgent();
  const { createUrl } = useCreateUrl();
  const { images, searchHistoryList, generateSearchUrlFromSearchHistory } = useHomeRecentSearch();

  const canRender = searchHistoryList.length !== 0 && !isMobile;

  if (!canRender) return null;

  return (
    <Container pb={isMobile ? 8 : 4}>
      <Stack gap={4}>
        <Stack.Item>
          <Text tagName="h2" variant="headline_2">
            {i18n.trans(t("flights_continue_search_title"))}
          </Text>
        </Stack.Item>
        <Stack.Item>
          <Grid attributes={{ style: { alignItems: "stretch" } }}>
            {searchHistoryList.map((item, idx) => (
              <Grid.Column size={isMobile ? 12 : 4} key={item.title || "" + item.subtitle + idx}>
                <Card variant="elevated" attributes={{ style: { height: "100%" } }}>
                  <a
                    style={{ color: "inherit", textDecoration: "none" }}
                    href={apiPrefix + createUrl(generateSearchUrlFromSearchHistory(item.searchhistoryItem))}
                  >
                    <Stack direction="row" gap={3}>
                      <Stack.Item>
                        <AspectRatio
                          attributes={{
                            style: {
                              borderRadius: "var(--bui_border_radius_100)",
                              backgroundColor: "var(--bui_color_foreground_inverted)",
                              backgroundPosition: "center center",
                              backgroundSize: "cover",
                              backgroundImage: `url("https://q-xx.bstatic.com${
                                images?.[item?.photoIata || ""]?.["square210"]
                              }")`
                            }
                          }}
                          ratio="1:1"
                          width="64px"
                        />
                      </Stack.Item>
                      <Stack.Item grow>
                        <Grid gap={2}>
                          <Grid.Column size={12}>
                            <div>
                              <Text className={styles.ellipsis} variant="strong_2">
                                {item.title}
                              </Text>
                              <Text className={styles.ellipsis} variant="body_2">
                                {item.subtitle}
                              </Text>
                            </div>
                          </Grid.Column>
                        </Grid>
                      </Stack.Item>
                    </Stack>
                  </a>
                </Card>
              </Grid.Column>
            ))}
          </Grid>
        </Stack.Item>
      </Stack>
    </Container>
  );
}
