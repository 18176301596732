import { UIFlightSegment, UIOrder } from "@flights/types";
import {
  FilteredLegWithOpenedCheckIn,
  getFilteredLegsWithOpenedCheckIn
} from "utils/get-filtered-legs-with-opened-checkIn";
import React from "react";

interface CheckInData {
  segment?: UIFlightSegment;
  segmentIndex?: number;
  filteredLegs: FilteredLegWithOpenedCheckIn[];
  hasCheckInData: boolean;
  hasAtLeastOneCheckInOpened: boolean;
  hasAtLeastOneCheckInLink: boolean;
  hasAtLeastOneContactUsLink: boolean;
}

export const useCheckInData = (order: UIOrder): CheckInData => {
  return React.useMemo(() => {
    const result: CheckInData = {
      hasCheckInData: false,
      // following 3 flags are used to track stages
      hasAtLeastOneCheckInOpened: false,
      hasAtLeastOneCheckInLink: false,
      hasAtLeastOneContactUsLink: false,
      filteredLegs: getFilteredLegsWithOpenedCheckIn(order.airOrder.flightSegments, order.carrierCheckinInfo)
    };

    if (result.filteredLegs.length !== 0) {
      const segmentIndex = result.filteredLegs[0].checkinInfoForLeg.legIdentifier.segmentIndex;
      result.segmentIndex = segmentIndex;
      result.segment = order.airOrder.flightSegments[segmentIndex];
      result.hasCheckInData = true;
      result.hasAtLeastOneCheckInOpened = result.filteredLegs.some(
        ({ checkinInfoForLeg }) => checkinInfoForLeg.isOnlineCheckinOpen
      );
      result.hasAtLeastOneCheckInLink = result.filteredLegs.some(
        ({ checkinInfoForLeg }) => !!checkinInfoForLeg.checkinLink
      );
      result.hasAtLeastOneContactUsLink = result.filteredLegs.some(({ leg }) => {
        const operatingCarrier = leg.flightInfo.carrierInfo?.operatingCarrier;

        if (!operatingCarrier) {
          return false;
        }

        const carrierTerms = order.orderTerms.carriers.find((carrier) => carrier.code === operatingCarrier);

        return Boolean(carrierTerms && carrierTerms.contactUsUrl);
      });
    }

    return result;
  }, [order.airOrder.flightSegments, order.carrierCheckinInfo, order.orderTerms.carriers]);
};
