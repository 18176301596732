/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Title, Carousel, Tab, useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import FlightsNearbyItem from "./CheapFlightsItem";
import { UICheapFlightInfo } from "@flights/types";
import Container from "../Container";
import Frame from "../Frame";
import styles from "./CheapFlights.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";
import { getCheapFlights } from "store/cheapFlights/selectors";

const [DOMESTIC, INTERNATIONAL] = ["DOMESTIC", "INTERNATIONAL"];

interface Props {
  isMobile: boolean;
}

interface WrapperProps {
  isMobile: boolean;
  children: React.ReactNode;
}

const Wrapper = ({ isMobile, children }: WrapperProps): React.ReactElement => {
  const theme = useTheme();

  return isMobile ? (
    <Frame grow={1} p={4} attributes={{ style: { width: "100%" } }}>
      {children}
    </Frame>
  ) : (
    <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>{children}</Container>
  );
};

const CheapFlights: React.FunctionComponent<Props> = ({ isMobile }) => {
  const i18n = useI18n();
  const cheapFlights = useSelector(getCheapFlights);
  const scrollRef = useRef<HTMLDivElement>(null);

  const domesticCheapFlights = useMemo(() => {
    return cheapFlights?.filter((flight) => flight.isDomestic) || [];
  }, [cheapFlights]);

  const internationalCheapFlights = useMemo(() => {
    return cheapFlights?.filter((flight) => !flight.isDomestic) || [];
  }, [cheapFlights]);

  useTrackLandingPageViewport(scrollRef, "popular_flights");

  const triggerList = [
    {
      text: i18n.trans(t("seo_flights_new_index_lp_cheap_flights_international_toggle")),
      id: INTERNATIONAL
    },
    {
      text: i18n.trans(t("seo_flights_new_index_lp_cheap_flights_domestic_toggle")),
      id: DOMESTIC
    }
  ];

  const panelList = [
    {
      id: DOMESTIC,
      children: (
        <Carousel
          attributes={{ id: `home_carouse_${DOMESTIC}` }}
          className={styles.carousel}
          size={isMobile ? "large" : "medium"}
          nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
          previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
        >
          {domesticCheapFlights.map((flight, index) => (
            <FlightsNearbyItem
              key={`dom-${index}`}
              flight={flight}
              index={index}
              isMobile={isMobile}
              component="popular_flights_domestic"
            />
          ))}
        </Carousel>
      )
    },
    {
      id: INTERNATIONAL,
      children: (
        <Carousel
          attributes={{ id: `home_carouse_${INTERNATIONAL}` }}
          className={styles.carousel}
          size={isMobile ? "large" : "medium"}
          nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
          previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
        >
          {internationalCheapFlights.map((flight, index) => (
            <FlightsNearbyItem
              key={`int-${index}`}
              flight={flight}
              index={index}
              isMobile={isMobile}
              component="popular_flights_international"
            />
          ))}
        </Carousel>
      )
    }
  ];

  const renderTabbedCarousels = () => {
    return (
      <Tab className={styles.tabs} defaultActiveTabId={triggerList[0].id}>
        <Tab.TriggerList>
          {triggerList.map((trigger) => (
            <Tab.Trigger key={trigger.id} linkAttributes={{ "aria-label": trigger.text }} {...trigger} />
          ))}
        </Tab.TriggerList>
        <div ref={scrollRef}>
          {panelList.map((panel) => (
            <Tab.Panel
              key={panel.id}
              id={panel.id}
              attributes={{ "aria-label": triggerList.find((trigger) => trigger.id === panel.id)?.text }}
            >
              <div className={styles.tabPanelContent}>{panel.children}</div>
            </Tab.Panel>
          ))}
        </div>
      </Tab>
    );
  };

  const renderSingleCarousel = (cheapFlights: UICheapFlightInfo[]) => {
    return (
      <Carousel
        className={styles.carousel}
        size={isMobile ? "large" : "medium"}
        nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
        previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
      >
        {cheapFlights.map((flight, index) => (
          <FlightsNearbyItem
            key={`che-${index}`}
            flight={flight}
            index={index}
            isMobile={isMobile}
            component="popular_flights_nearby"
          />
        ))}
      </Carousel>
    );
  };

  return cheapFlights && cheapFlights.length > 0 ? (
    <Wrapper isMobile={isMobile}>
      <Title
        className={
          domesticCheapFlights.length > 0 && internationalCheapFlights.length > 0
            ? styles.tabbedCarouselTitle
            : styles.singleCarouselTitle
        }
        title={i18n.trans(t("seo_flights_new_index_lp_popular_flights_header"))}
        subtitle={i18n.trans(t("seo_flights_new_index_lp_cheap_flights_subheader"))}
        variant="headline_2"
        titleTagName="h2"
      />
      <div>
        {domesticCheapFlights.length > 0 && internationalCheapFlights.length > 0
          ? renderTabbedCarousels()
          : renderSingleCarousel(cheapFlights)}
      </div>
    </Wrapper>
  ) : null;
};
export default CheapFlights;
