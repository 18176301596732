import React, { Profiler } from "react";
import { useParams } from "react-router-dom";

import { AppShellDesktop } from "components/elements/AppShell";
import ConfirmationInnerLoader from "components/elements/ConfirmationInnerLoader";
import Container from "components/elements/Container";
import { assert } from "utils/assert";
import useClientMetrics from "../hooks/useClientMetrics";
import { PageHeader } from "./Home";
import { WebCheckinInnerV2 } from "../components/elements/WebCheckinInner";

const WebcheckinV2: React.FC = () => {
  const params = useParams<{ orderToken: string; segmentIndex: string }>();
  const { trackRenderTime } = useClientMetrics();
  const segmentIndex = Number(params?.segmentIndex);
  assert(params?.orderToken, "Missing orderToken parameter");
  assert(!isNaN(segmentIndex), "segmentIndex parameter must be a number");

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShellDesktop>
        <Container pt={8} pb={8}>
          <ConfirmationInnerLoader token={params?.orderToken} cache={true}>
            <WebCheckinInnerV2 segmentIndex={segmentIndex} />
          </ConfirmationInnerLoader>
        </Container>
      </AppShellDesktop>
    </Profiler>
  );
};

export default WebcheckinV2;
