import { Formats, useFormatDateTime } from "@bookingcom/flights-core";
import { I18nChildContext, useI18n, t } from "@bookingcom/lingojs-react";
import { UICityImage } from "@flights/types";
import useSearchHistory from "hooks/useSearchHistory";
import { useEffect, useMemo, useState } from "react";
import clientFetch from "utils/client-fetch";
import {
  LOCATION_IATA_CODE_AND_TYPE_SEPARATOR,
  MULTISTOP_IATA_LIST_SEPARATOR,
  PARAM_LIST_SEPARATOR
} from "@flights/constants";

const getDate = (item: SearchHistory, formats: Formats, i18n: I18nChildContext) => {
  const multiStopDatesArr = (item.multiStopDates || "").split(MULTISTOP_IATA_LIST_SEPARATOR);
  const multiStopDepart = multiStopDatesArr[0];
  const multiStopReturn = multiStopDatesArr[multiStopDatesArr.length - 1];

  const isMultiCityDates = !!multiStopDepart && !!multiStopReturn;
  const isroundTripDates = !isMultiCityDates && !!item.depart && !!item.return;
  const isOneWayDate = !isroundTripDates && !!item.depart;

  if (!isOneWayDate && !isroundTripDates && !isMultiCityDates) return undefined;

  return isOneWayDate
    ? formats.flightDate(item.depart || "")
    : isroundTripDates
    ? i18n.trans(
        t("flights_continue_search_card_date", {
          variables: {
            depart_date: formats.flightDate(item.depart || ""),
            arrive_date: formats.flightDate(item.return || "")
          }
        })
      )
    : isMultiCityDates
    ? i18n.trans(
        t("flights_continue_search_card_date", {
          variables: {
            depart_date: formats.flightDate(multiStopDepart || ""),
            arrive_date: formats.flightDate(multiStopReturn || "")
          }
        })
      )
    : undefined;
};

const getTraveller = (item: SearchHistory, i18n: I18nChildContext) => {
  const adults = item.adults;
  if (!adults) return undefined;
  const children = item.children || "";
  const count = Number(adults) + Number(children.split(",").filter((a) => a !== "").length);
  return i18n.trans(
    t("flights_continue_search_card_travellers", {
      num_exception: count,
      variables: { num_travellers: count }
    })
  );
};

const getTripType = (item: SearchHistory, i18n: I18nChildContext) => {
  const type = item.type;
  if (!type) return undefined;
  return type === "ONEWAY"
    ? i18n.trans(t("flights_continue_search_card_one_way"))
    : type === "ROUNDTRIP"
    ? i18n.trans(t("flights_continue_search_card_round_trip"))
    : i18n.trans(t("flights_continue_search_card_multi"));
};

const getTitle = (item: SearchHistory, i18n: I18nChildContext) => {
  const fromLocationName = (item.fromLocationName || "").split(MULTISTOP_IATA_LIST_SEPARATOR);
  const toLocationName = (item.toLocationName || "").split(MULTISTOP_IATA_LIST_SEPARATOR);
  if (!fromLocationName.filter((a) => Boolean(a)).length) return undefined;
  if (!toLocationName.filter((a) => Boolean(a)).length) return undefined;
  const titleList = fromLocationName.map((fromItem, idx) => {
    const originCity = fromItem
      .split(PARAM_LIST_SEPARATOR)
      .join(`${i18n.trans(t("flights_continue_search_multi_separator"))} `);
    const destinationCity = toLocationName[idx]
      ?.split(PARAM_LIST_SEPARATOR)
      .join(`${i18n.trans(t("flights_continue_search_multi_separator"))} `);
    if (!originCity && !destinationCity) return undefined;
    return i18n.trans(
      t("flights_continue_search_card_title", {
        variables: { origin_city: originCity, destination_city: destinationCity }
      })
    );
  });
  if (!titleList.filter((a) => Boolean(a)).length) return undefined;
  let title = titleList.splice(0, 2).join(`${i18n.trans(t("flights_continue_search_multi_separator"))} `); //show first two
  if (titleList.length) {
    title = `${title} ${i18n.trans(
      t("flights_continue_search_multi_last", { variables: { num_flights: titleList.length } })
    )}`;
  }
  return title;
};

const getPhotoIata = (item: SearchHistory) => {
  return item.to?.split(MULTISTOP_IATA_LIST_SEPARATOR)?.[0]?.split(LOCATION_IATA_CODE_AND_TYPE_SEPARATOR)?.[0];
};

const getSubtitle = (item: SearchHistory, formats: Formats, i18n: I18nChildContext) => {
  const formattedDate = getDate(item, formats, i18n);
  const travellersCount = getTraveller(item, i18n);
  const tripType = getTripType(item, i18n);
  if (!formattedDate || !travellersCount || !tripType) return undefined;
  return `${formattedDate}, ${travellersCount}, ${tripType}`;
};

export default function useHomeRecentSearch() {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { generateSearchUrlFromSearchHistory, searchHistory } = useSearchHistory();
  const [images, setImages] = useState<UICityImage>({});

  const searchHistoryList = useMemo(
    () =>
      searchHistory
        .map((item) => ({
          searchhistoryItem: item,
          title: getTitle(item, i18n),
          subtitle: getSubtitle(item, formats, i18n),
          photoIata: getPhotoIata(item)
        }))
        .filter((item) => !!item.title && !!item.subtitle)
        .slice(0, 3),
    [searchHistory] // eslint-disable-line react-hooks/exhaustive-deps
  );

  const canRender = useMemo(() => !!searchHistory.length, [searchHistory]);

  useEffect(() => {
    const iata = new Set();
    searchHistory.forEach((item) => {
      const i = item.to?.split(MULTISTOP_IATA_LIST_SEPARATOR)?.[0]?.split(LOCATION_IATA_CODE_AND_TYPE_SEPARATOR)?.[0];
      if (i) iata.add(i.toUpperCase());
    });
    const fetchData = async () => {
      await clientFetch(`/api/cityImage?iata=${[...iata].join("-")}`, {
        method: "GET",
        credentials: "same-origin",
        headers: { "Content-Type": "application/json" }
      })
        .then((d: UICityImage) => setImages(d))
        .catch(() => {});
    };
    if (canRender) void fetchData();
  }, [searchHistory, canRender]);

  return {
    images,
    searchHistoryList,
    generateSearchUrlFromSearchHistory
  };
}
