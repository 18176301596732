import React from "react";
import { Icon, Popover, Text } from "@bookingcom/bui-react";
import { useI18n, Trans } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import styles from "./styles.module.css";

export function PriceDisclaimerDesktop() {
  const i18n = useI18n();
  return (
    <Popover
      triggerType="hover"
      position="top"
      hideClose={true}
      navigationMode="tab"
      trapFocusMode="soft"
      keepMounted={true}
    >
      <Popover.Trigger>
        {(attrs) => (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <span tabIndex={0} {...attrs} aria-labelledby="price_disclaimer_tooltip" className={styles.icon_wrapper}>
            <Icon attributes={{ "aria-hidden": true }} size="small" svg={InfoSignIcon} />
          </span>
        )}
      </Popover.Trigger>
      <Popover.Content
        attributes={{
          "aria-label": i18n.trans(t("flights_search_explore_price_accuracy_banner")),
          role: "tooltip",
          id: "price_disclaimer_tooltip"
        }}
      >
        <Text variant="body_1">
          <Trans tag="flights_search_explore_price_accuracy_banner" />
        </Text>
      </Popover.Content>
    </Popover>
  );
}
