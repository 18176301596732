/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useState, useCallback, CSSProperties, useMemo } from "react";
import constate from "constate";
import useUserAgent from "../../../hooks/useUserAgent";
import { useTheme } from "@bookingcom/bui-react";
import styles from "./Wrapper.module.css";

export interface WrapperProps extends Partial<WrapperDefaultProps> {
  children: React.ReactElement | React.ReactElement[];
}

export type WrapperProviderProps = Partial<WrapperProviderDefaultProps>;

export interface WrapperProviderDefaultProps {
  padding: CSSProperties["padding"];
  inlineStyles: CSSProperties;
  children?: React.ReactNode;
  elementRef?: React.Ref<HTMLDivElement>;
}

export type WrapperContextProps = WrapperProviderProps & {
  render: boolean;
  dismiss: Function;
};

export interface WrapperDefaultProps {
  noBorder: boolean;
  noPadding: boolean;
  noPaddingVertical: boolean;
  style: React.CSSProperties;
  elementRef?: React.Ref<HTMLDivElement>;
}

const defaultProps: WrapperDefaultProps = {
  noBorder: false,
  noPadding: false,
  noPaddingVertical: false,
  style: {}
};

export default function Wrapper(props: WrapperProps) {
  const { noBorder, noPadding, style, children, noPaddingVertical, elementRef } = { ...defaultProps, ...props };
  const theme = useTheme();
  const { isMobile } = useUserAgent();

  const defaultBorderStyles = useMemo(() => {
    if (noBorder) {
      return {};
    }

    if (isMobile) {
      return { borderBottom: `${theme.units.border_width_100} solid ${theme.colors.color_border_alt}` };
    } else {
      return {
        border: `${theme.units.border_width_100} solid ${theme.colors.color_border_alt}`,
        borderRadius: theme.units.border_radius_100
      };
    }
  }, [isMobile, noBorder, theme.colors.color_border_alt, theme.units.border_radius_100, theme.units.border_width_100]);

  const paddingVertical = noPaddingVertical ? 0 : isMobile ? theme.units.spacing_4x : theme.units.spacing_6x;
  const paddingHorizontal = noPadding ? 0 : paddingVertical;

  const defaultPaddingStyles = useMemo(
    () => ({
      paddingLeft: paddingHorizontal,
      paddingRight: paddingHorizontal,
      paddingTop: paddingVertical,
      paddingBottom: paddingVertical
    }),
    [paddingHorizontal, paddingVertical]
  );

  return (
    <WrapperProvider
      padding={paddingHorizontal}
      inlineStyles={{ ...defaultPaddingStyles, ...defaultBorderStyles, ...style }}
      elementRef={elementRef}
    >
      <WrapperRenderer>{children}</WrapperRenderer>
    </WrapperProvider>
  );
}

function WrapperRenderer(props: Pick<WrapperProps, "children">) {
  const { render, inlineStyles, elementRef } = useWrapperContext();
  const { padding } = useWrapperContext();
  if (!render) return null;

  return (
    <div style={{ padding, ...inlineStyles }} className={styles.root} ref={elementRef}>
      {props.children}
    </div>
  );
}

function useWrapperProvider(props?: WrapperProviderProps): WrapperContextProps {
  const [render, setRender] = useState(true);

  const dismiss = useCallback(() => {
    setRender(false);
  }, [setRender]);

  const { padding, inlineStyles, elementRef } = { ...defaultProps, ...props };

  return {
    padding,
    inlineStyles,
    elementRef,
    render,
    dismiss
  };
}

const [WrapperProvider, useWrapperContext] = constate(useWrapperProvider);
export { WrapperProvider, useWrapperContext };
