import React, { useRef } from "react";
import { Card, Grid, GridColumn, Title, Text } from "@bookingcom/bui-react";
import { FaqItem, getFaqData } from "./FrequentlyAskedQuestionsData";
import { t, useI18n } from "@bookingcom/lingojs-react";
import styles from "./FrequentlyAskedQuestions.desktop.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";

/**
 * FAQ block on Flights index page on Desktop web.
 */
const FrequentlyAskedQuestionsDesktop: React.FunctionComponent = () => {
  const i18n = useI18n();
  const faqItemList: FaqItem[] = getFaqData(i18n);
  const scrollRef = useRef<HTMLDivElement>(null);

  useTrackLandingPageViewport(scrollRef, "faq");

  const renderFaqGrid = () => {
    return (
      <Grid bleed={false}>
        {faqItemList.map((faq, index) => (
          <GridColumn size={4} key={index}>
            <div
              className={styles.faqCardContainer}
              itemScope
              itemProp="mainEntity"
              itemType="https://schema.org/Question"
            >
              <Card variant="neutral" className={styles.faqCard}>
                <Title
                  className={styles.question}
                  variant="strong_1"
                  title={faq.question}
                  attributes={{ itemProp: "name" }}
                />
                <div itemScope itemProp="acceptedAnswer" itemType="https://schema.org/Answer">
                  <Text attributes={{ itemProp: "text" }} variant="body_2">
                    {faq.answer}
                  </Text>
                </div>
              </Card>
            </div>
          </GridColumn>
        ))}
      </Grid>
    );
  };

  return (
    <div ref={scrollRef}>
      <Title
        title={i18n.trans(t("seo_flights_new_index_lp_faq_header"))}
        variant="headline_2"
        className={styles.title}
        titleTagName="h2"
      />
      <div itemScope itemType="https://schema.org/FAQPage">
        {renderFaqGrid()}
      </div>
    </div>
  );
};
export default FrequentlyAskedQuestionsDesktop;
