import React, { useCallback } from "react";
import { AspectRatio, Box, Image, Text } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import { useFormatDateTime } from "@bookingcom/flights-core";

import useGlobalContext from "hooks/useGlobalContext";
import useFormatPrice from "hooks/useFormatPrice";
import useCreateUrl from "hooks/useCreateUrl";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";

import { currencySymbolByPointOfSale } from "data/pointOfSaleToCurrency";
import { trackIndexCampaign } from "utils/experiments/customer-aquisition/flights_web_cat_banner_campaign_index";
import getRoundtripSearchUrl from "utils/search-urls";

import { IMAGE_STATIC_ASSETS_URL } from "../../../constants";
import { SPACER_PADDED } from "@flights/constants";
import { UITrendingDestinationFlightInfo } from "@flights/types";
import styles from "./TrendingDestinationsItem.module.css";
import flights_web_cat_theme_based_destinations_www from "utils/experiments/customer-aquisition/flights_web_cat_theme_based_destinations_www";

interface Props {
  destination: UITrendingDestinationFlightInfo;
  index: number;
  component: "trending_cities";
}

interface Image {
  src: string;
  alt: string;
}

const getImage = (toCityImageUrl: string, toCityName: string): Image => {
  return {
    src: `${IMAGE_STATIC_ASSETS_URL}${toCityImageUrl}`,
    alt: toCityName
  };
};

const getSearchUrl = (destination: UITrendingDestinationFlightInfo): string => {
  return getRoundtripSearchUrl(
    {
      fromIata: destination.fromCityIata,
      toIata: destination.toCityIata,
      departureDate: destination.tripStartDate,
      returnDate: destination.tripEndDate
    },
    "flights_index_td"
  );
};

const TrendingDestinationsItem: React.FunctionComponent<Props> = ({ destination, index, component }) => {
  const i18n = useI18n();
  const { formats } = useFormatDateTime(i18n);
  const { ipCountry, indexPageCampaign } = useGlobalContext();
  const { createUrlWithApiPrefix } = useCreateUrl();
  const { formatPrice } = useFormatPrice();
  const trackLandingPageEvents = useLandingPageEvents();

  const destinationLocalised = i18n.trans(
    t("seo_flights_new_index_lp_city_meta_city_destination", {
      variables: { city_name: destination.toCityNameLocalised, country_id: destination.toCountryCode }
    })
  );

  const fromDateFormatted = formats.flightDate(destination.tripStartDate);
  const toDateFormatted = formats.flightDate(destination.tripEndDate);
  const flightParams =
    `${fromDateFormatted} - ${toDateFormatted}` +
    SPACER_PADDED +
    `${i18n.trans(t("seo_flights_new_index_lp_cheap_flights_meta_round_trip"))}`;

  // Optionally show origin Airport information.
  const departureAirport = i18n.trans(
    t("seo_flights_new_index_lp_trending_cities_from_airport", {
      variables: { airport_name: destination.fromAirportName || "" }
    })
  );

  // Optionally show price information.
  // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
  const currency = currencySymbolByPointOfSale[ipCountry as string];
  const fromPriceFormated = destination.price?.units
    ? formatPrice({ currencyCode: currency, units: destination.price?.units, nanos: 0 })
    : "";
  const fromPriceLocalised = i18n.trans(
    t("seo_flights_new_index_lp_cheap_flights_meta_flight_from_price", {
      variables: { flight_price: fromPriceFormated, num_exception: 1 }
    })
  );

  const url = createUrlWithApiPrefix(getSearchUrl(destination));

  const handleClick = useCallback(() => {
    if (indexPageCampaign) {
      trackIndexCampaign.goals.carousels_click();
    }

    trackLandingPageEvents("click", component, { ...destination, position: index + 1, url });
    flights_web_cat_theme_based_destinations_www.goals.trending_cities_or_popular_flights_item_click_5();
  }, [component, destination, index, indexPageCampaign, trackLandingPageEvents, url]);

  const imageUrl = destination.toCityOtherImageSlugs?.["526x420:webp"]?.imageUrlSlug?.[0];

  return (
    <a className={styles.anchorContainer} href={url} onClick={handleClick} onAuxClick={handleClick}>
      <Box padding={0}>
        <AspectRatio ratio="4:3">
          <Image
            className={styles.image}
            {...getImage(imageUrl || "", destinationLocalised)}
            fallback="image"
            fallbackImageSrc={`${IMAGE_STATIC_ASSETS_URL}${destination.toCityImageUrl}`}
          />
        </AspectRatio>
        <div className={styles.textContainer}>
          <Text className={styles.textLine} variant="strong_1">
            {destinationLocalised}
          </Text>
          {destination.fromAirportName && (
            <Text className={styles.textLine} variant="body_2" color="neutral_alt">
              {departureAirport}
            </Text>
          )}
          <Text className={styles.textLine} variant="body_2" color="neutral_alt">
            {flightParams}
          </Text>
          {fromPriceFormated !== "" && (
            <Text className={styles.textLine} variant="body_2" color="neutral_alt">
              {fromPriceLocalised}
            </Text>
          )}
        </div>
      </Box>
    </a>
  );
};
export default TrendingDestinationsItem;
