import React, { ReactNode } from "react";
import { Icon, Stack, Text } from "@bookingcom/bui-react";
import { QuestionMarkCircleIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import { Color, Size } from "@bookingcom/bui-react/components/Icon/Icon.types";

type Props = {
  text: ReactNode;
  iconColor?: Color;
  iconSize?: Size;
};

const HintAlert = ({ text, iconColor = "action", iconSize = "smaller" }: Props) => {
  return (
    <Stack direction="row" alignItems="center">
      <Icon color={iconColor} size={iconSize} svg={QuestionMarkCircleIcon} />
      <Stack.Item grow={true}>
        <Text variant="body_2">{text}</Text>
      </Stack.Item>
    </Stack>
  );
};

export default HintAlert;
