import React from "react";
import Container from "components/elements/Container";
import { FlightCampaignTCBanner } from "../FlightCampaignTCBanner";
import useGlobalContext from "hooks/useGlobalContext";
import Frame from "../Frame/Frame";
import {
  trackIndexCampaign,
  useTrackIndexCampaignStages
} from "utils/experiments/customer-aquisition/flights_web_cat_banner_campaign_index";

/**
 * Banner showing generic information about current deals
 */
export function IndexCampaignBanner() {
  const { indexPageCampaign } = useGlobalContext();

  useTrackIndexCampaignStages();

  if (!indexPageCampaign || !trackIndexCampaign.variant()) return null;

  return (
    <Container>
      <Frame mt={6} mb={8}>
        <FlightCampaignTCBanner
          campaignBanner={indexPageCampaign}
          onCtaClick={trackIndexCampaign.goals.view_terms_click}
        />
      </Frame>
    </Container>
  );
}
