import React, { useCallback, useMemo } from "react";
import { Carousel, Grid, GridColumn } from "@bookingcom/bui-react";
import { Panel, Trigger } from "@bookingcom/bui-react/components/Tab/Tab.types";
import { State as FlightsInterlinkingData } from "store/flightsInterlinking/state";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import FlightsInterlinkingItem from "components/elements/FlightsInterlinking/FlightsInterlinkingItem";
import styles from "./FlightsInterlinkingDesktop.module.css";
import { UIFlightsInterlinkingPanelContext } from "@flights/types";
import useUserAgent from "hooks/useUserAgent";

export type FlightsInterlinkingTriggerIds = "citiesToCities" | "cities" | "countries" | "regions" | "airports";

const groupItems = <T,>(data: T[]): T[][] => {
  const grouped: T[][] = [];
  const chunkSize = 3;

  for (let start = 0; start < data.length; start += chunkSize) {
    grouped.push(data.slice(start, start + chunkSize));
  }

  return grouped;
};

export const useFlightsInterlinkingPanelList = (
  data: FlightsInterlinkingData
): { panelsList: Panel[]; triggerList: Trigger[] } => {
  const i18n = useI18n();
  const { isMobile } = useUserAgent();

  const triggerList: Trigger[] = useMemo(() => {
    return [
      { id: "citiesToCities", text: i18n.trans(t("flights_interlinking_index_tab_1")) },
      { id: "cities", text: i18n.trans(t("flights_interlinking_index_tab_2")) },
      { id: "countries", text: i18n.trans(t("flights_interlinking_index_tab_3")) },
      { id: "regions", text: i18n.trans(t("flights_interlinking_index_tab_4")) },
      { id: "airports", text: i18n.trans(t("flights_interlinking_index_tab_5")) }
    ];
  }, [i18n]);

  const createPanelContent = useCallback(
    <T extends UIFlightsInterlinkingPanelContext>(
      items: T[],
      itemRenderer: (item: T, index: number) => React.ReactNode
    ) => {
      const groupedItems = groupItems(items);

      if (isMobile) {
        return (
          <Carousel
            nextButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_next_button_aria_label"))}
            previousButtonAriaLabel={i18n.trans(t("a11y_flights_route_carousel_previous_button_aria_label"))}
            className={styles.carousel}
          >
            {groupedItems.map((group, groupIndex) => (
              <div key={`col-${groupIndex}`} className={styles.mobileColumn}>
                {group.map((item, index) => itemRenderer(item, index))}
              </div>
            ))}
          </Carousel>
        );
      }

      return (
        <Grid className={styles.grid}>
          {items.map((item, index) => (
            <GridColumn
              key={index}
              size={{
                s: 6,
                m: 4
              }}
            >
              {itemRenderer(item, index)}
            </GridColumn>
          ))}
        </Grid>
      );
    },
    [isMobile, i18n]
  );

  const panelsList = useMemo(() => {
    const panels: Panel[] = [];

    if (data?.citiesToCities?.length) {
      panels.push({
        id: triggerList[0].id,
        children: createPanelContent(data.citiesToCities, (route, index) => (
          <FlightsInterlinkingItem
            key={index}
            flight={{
              type: triggerList[0].id as FlightsInterlinkingTriggerIds,
              departureTranslatedName: route.departure?.translatedName || "",
              departurePageName: route.departure?.pageName || "",
              arrivalTranslatedName: route.arrival?.translatedName || "",
              arrivalPageName: route.arrival?.pageName || "",
              image: route.arrival?.images?.[0]?.urlSlug || "",
              countryCodeDeparture: route.departure?.countryCode || "",
              countryCodeArrival: route.arrival?.countryCode || ""
            }}
          />
        ))
      });
    }

    if (data?.cities?.length) {
      panels.push({
        id: triggerList[1].id,
        children: createPanelContent(data.cities, (city, index) => (
          <FlightsInterlinkingItem
            key={index}
            flight={{
              type: triggerList[1].id as FlightsInterlinkingTriggerIds,
              cityTranslatedName: city.translatedName || "",
              image: city.images?.[0]?.urlSlug || "",
              countryCode: city.countryCode || "",
              pageName: city.pageName || ""
            }}
          />
        ))
      });
    }

    if (data?.countries?.length) {
      panels.push({
        id: triggerList[2].id,
        children: createPanelContent(data.countries, (country, index) => (
          <FlightsInterlinkingItem
            key={index}
            flight={{
              type: triggerList[2].id as FlightsInterlinkingTriggerIds,
              countryTranslatedName: country.translatedName || "",
              image: country.images?.[0]?.urlSlug || "",
              countryCode: country.countryCode || ""
            }}
          />
        ))
      });
    }

    if (data?.regions?.length) {
      panels.push({
        id: triggerList[3].id,
        children: createPanelContent(data.regions || [], (region, index) => (
          <FlightsInterlinkingItem
            key={index}
            flight={{
              type: triggerList[3].id as FlightsInterlinkingTriggerIds,
              regionTranslatedName: region.translatedName || "",
              image: region.images?.[0]?.urlSlug || "",
              countryCode: region.countryCode || "",
              pageName: region.pageName || ""
            }}
          />
        ))
      });
    }

    if (data?.airports?.length) {
      panels.push({
        id: triggerList[4].id,
        children: createPanelContent(data.airports || [], (airport, index) => (
          <FlightsInterlinkingItem
            key={index}
            flight={{
              type: triggerList[4].id as FlightsInterlinkingTriggerIds,
              airportTranslatedName: airport.translatedName || "",
              iata: airport.iata || "",
              image: airport.images?.[0]?.urlSlug || "",
              countryCode: airport.countryCode || ""
            }}
          />
        ))
      });
    }

    return panels;
  }, [createPanelContent, data, triggerList]);

  return { panelsList, triggerList };
};
