import React from "react";
import { useHistory } from "react-router-dom";

import { Breadcrumbs, Button, Divider, Stack, useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { UIFlightSegment, UIOrder } from "@flights/types";
import useEventTracking from "hooks/useEventTracking";
import useGlobalContext from "hooks/useGlobalContext";
import useCreateUrl from "hooks/useCreateUrl";
import { FilteredLegWithOpenedCheckIn } from "utils/get-filtered-legs-with-opened-checkIn";

import ConfirmationController from "../ConfirmationController";
import { CheckInInfoCard } from "./CheckinInfoCard";
import TwoColumnLayout from "../TwoColumnLayout";
import { CheckinHeader } from "./CheckinHeader";
import { CheckinHowTo } from "./CheckinHowTo";
import { CheckinSegmentHeader } from "./CheckinSegmentHeader";

interface WebCheckinInnerDesktopProps {
  order: UIOrder;
  segmentIndex: number;
  segment: UIFlightSegment;
  filteredLegsWithOpenedCheckIn: FilteredLegWithOpenedCheckIn[];
}

export const WebCheckinInnerDesktop: React.FC<WebCheckinInnerDesktopProps> = ({
  order,
  segmentIndex,
  segment,
  filteredLegsWithOpenedCheckIn
}) => {
  const i18n = useI18n();
  const history = useHistory();
  const theme = useTheme();
  const { requestId } = useGlobalContext();
  const trackV2 = useEventTracking("pb_webcheckin", requestId);
  const { createUrl } = useCreateUrl();

  const handleSkipAllClick = () => {
    trackV2("click_skip_checkin", {
      order_id: order.orderId,
      segment: segmentIndex,
      legs: [...segment.legs.keys()]
    });
    history.push(createUrl(`/booking/order-details/${order.orderToken}`));
  };

  const showSkipAllButton = React.useMemo(() => {
    const openedCheckInsCount = filteredLegsWithOpenedCheckIn.filter(({ checkinInfoForLeg }) => {
      return checkinInfoForLeg.isOnlineCheckinOpen;
    }).length;

    return openedCheckInsCount > 1;
  }, [filteredLegsWithOpenedCheckIn]);

  return (
    <ConfirmationController>
      {() => (
        <main>
          <Breadcrumbs
            back
            items={[
              {
                text: i18n.trans(t("flights_pb_checkin_navigation")),
                href: createUrl(`/booking/order-details/${order?.orderToken}`)
              }
            ]}
            attributes={{ style: { paddingBottom: theme.units.spacing_2x } }}
          />
          <Stack gap={6}>
            <CheckinHeader segment={segment} />
            <TwoColumnLayout>
              <div style={{ marginBottom: theme.units.spacing_8x }}>
                <Stack gap={8}>
                  <CheckinHowTo order={order} />
                  <Divider />
                  <Stack gap={4}>
                    <CheckinSegmentHeader
                      segment={segment}
                      showSubHeader={filteredLegsWithOpenedCheckIn.length === 1}
                    />
                    {filteredLegsWithOpenedCheckIn.map(({ leg, checkinInfoForLeg }) => {
                      const {
                        legIdentifier: { segmentIndex, legIndex },
                        isOnlineCheckinOpen
                      } = checkinInfoForLeg;
                      return (
                        <CheckInInfoCard
                          key={legIndex}
                          leg={leg}
                          segmentIndex={segmentIndex}
                          segment={segment}
                          legIndex={legIndex}
                          openCheckin={isOnlineCheckinOpen}
                          checkInInfo={checkinInfoForLeg}
                          showSkipAllButton={showSkipAllButton}
                          showHeader={filteredLegsWithOpenedCheckIn.length > 1}
                          showVuelingAlert
                        />
                      );
                    })}
                    {showSkipAllButton && (
                      <Stack direction="row" justifyContent="end">
                        <Button
                          size="large"
                          attributes={{ "aria-label": i18n.trans(t("a11y_flights_pb_checkin_vi_skip_cta")) }}
                          variant="tertiary"
                          onClick={handleSkipAllClick}
                        >
                          {i18n.trans(t("flights_pb_checkin_vi_skip_cta"))}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Stack>
              </div>
            </TwoColumnLayout>
          </Stack>
        </main>
      )}
    </ConfirmationController>
  );
};
