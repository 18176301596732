type FlightsSearchUrlModel = {
  fromIata: string;
  toIata: string;
  departureDate: string;
  returnDate: string;
};

/**
 * Creates basic roundtrip flight search URL.
 *
 * @param data data for flights search.
 * @param caSource tracking source.
 * @returns roundtrip search url.
 */
export default function getRoundtripSearchUrl(data: FlightsSearchUrlModel, caSource: string): string {
  const searchParams = new URLSearchParams();
  searchParams.set("type", "ROUNDTRIP");
  searchParams.set("adults", "1");
  searchParams.set("cabinClass", "ECONOMY");
  searchParams.set("sort", "BEST");

  searchParams.set("depart", data.departureDate);
  searchParams.set("return", data.returnDate);

  searchParams.set("from", data.fromIata);
  searchParams.set("to", data.toIata);

  return `/flights/${data.fromIata}-${data.toIata}/?${searchParams.toString()}&ca_source=${caSource}`;
}
