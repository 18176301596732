import React from "react";

import { Grid, GridColumn, Icon, Image, Popover, Text } from "@bookingcom/bui-react";
import { Trans, useI18n } from "@bookingcom/lingojs-react";
import { t } from "@bookingcom/lingojs-core";
import Container from "../Container";
import useIsBR from "hooks/useIsBR";
import { trackCustomGoal, trackExperiment } from "utils/et";
import useGlobalContext from "hooks/useGlobalContext";
import Frame from "../Frame";
import { mcn } from "utils/mergeClassnames";
import styles from "./UspBlock.module.css";
import { InfoSignIcon } from "@bookingcom/bui-assets-react/streamline/index.js";
import useUserAgent from "hooks/useUserAgent";

interface Props {
  hideBackground?: boolean;
  isPPCVariant?: boolean;
}

export type USP = {
  asset: {
    setName: "illustrations-traveller";
    assetName: "CustomerSupport" | "MagnifyingGlassUsp" | "MoneyUsp" | "TicketsUsp";
  };
  header: string;
  subheader: string;
  tooltip?: JSX.Element;
};

export type USPs = USP[];

const UspBlock: React.FunctionComponent<Props> = ({ hideBackground = false, isPPCVariant = false }) => {
  const i18n = useI18n();

  const isBR = useIsBR();
  const { isCustomerServiceEnabledForPOS } = useGlobalContext();
  const showCsUsp = isCustomerServiceEnabledForPOS && !!trackExperiment("flights_web_cs_usp_index");

  const { isMobile } = useUserAgent();
  const showUsp3Tooltip = isPPCVariant && !isMobile;
  const useSmallerSizes = isPPCVariant && isMobile;

  const usps: USPs = [
    showCsUsp
      ? {
          asset: {
            setName: "illustrations-traveller",
            assetName: "CustomerSupport"
          },
          header: i18n.trans(t("flights_index_cs_usp_header")),
          subheader: i18n.trans(t("flights_index_cs_usp_subheader"))
        }
      : {
          asset: {
            setName: "illustrations-traveller",
            assetName: "MagnifyingGlassUsp"
          },
          header: i18n.trans(t("flights_index_usp1_header")),
          subheader: i18n.trans(t("flights_index_usp1_copy"))
        },
    isBR
      ? {
          asset: {
            setName: "illustrations-traveller",
            assetName: "MoneyUsp"
          },
          header: i18n.trans(t("flights_index_instalment_usp_header")),
          subheader: i18n.trans(t("flights_index_instalment_usp_copy"))
        }
      : {
          asset: {
            setName: "illustrations-traveller",
            assetName: "MoneyUsp"
          },
          header: i18n.trans(t("flights_index_usp2_header")),
          subheader: i18n.trans(t("flights_index_usp2_copy"))
        },
    {
      asset: {
        setName: "illustrations-traveller",
        assetName: "TicketsUsp"
      },
      header: i18n.trans(t("flights_index_usp3_header")),
      subheader: showUsp3Tooltip
        ? i18n.trans(t("flights_index_usp3_copy_no_asterisk"))
        : i18n.trans(t("flights_index_usp3_copy")),
      tooltip: showUsp3Tooltip ? (
        <Popover
          triggerType="hover"
          position="bottom-end"
          hideClose={true}
          navigationMode="tab"
          trapFocusMode="soft"
          keepMounted={true}
          onOpen={() => isPPCVariant && trackCustomGoal("flights_web_cat_ppc_without_search_history", 1)}
        >
          <Popover.Trigger>
            {(attrs) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
              <span tabIndex={0} {...attrs} aria-labelledby="usp_3_tooltip" className={styles.icon_wrapper}>
                <Icon attributes={{ "aria-hidden": true }} size="small" svg={InfoSignIcon} />
              </span>
            )}
          </Popover.Trigger>
          <Popover.Content
            attributes={{
              "aria-label": i18n.trans(t("flights_index_usp3_disclaimer_no_asterisk")),
              role: "tooltip",
              id: "usp_3_tooltip"
            }}
          >
            <Text variant="body_1">
              <Trans tag="flights_index_usp3_disclaimer_no_asterisk" />
            </Text>
          </Popover.Content>
        </Popover>
      ) : undefined
    }
  ];

  return (
    <>
      <Container className={mcn(!hideBackground && styles.container)}>
        <Grid className={mcn(styles.grid, isPPCVariant && !hideBackground && styles.grid__no_mt)}>
          {usps.map((usp: USP, index: number) => (
            <GridColumn
              className={mcn(styles.uspContainer, useSmallerSizes && styles.uspContainer__padding)}
              key={index}
              size={{
                s: 12,
                m: 4
              }}
            >
              <Frame wrap="nowrap" alignItems="center" direction="row">
                <Frame className={mcn(!useSmallerSizes && styles.uspImageWrapper)} mr={4}>
                  <Image
                    asset={usp.asset}
                    width={useSmallerSizes ? "48px" : "80px"}
                    height={useSmallerSizes ? "48px" : "80px"}
                    contentMode="fit"
                    attributes={{ "aria-hidden": true }}
                  />
                </Frame>
                <div>
                  <Text variant={useSmallerSizes ? "strong_2" : "headline_3"}>{usp.header}</Text>
                  <Text variant={useSmallerSizes ? "small_1" : "body_2"} color="neutral_alt">
                    {usp.subheader} {!!usp.tooltip && usp.tooltip}
                  </Text>
                </div>
              </Frame>
            </GridColumn>
          ))}
        </Grid>
      </Container>
      {!showUsp3Tooltip && (
        <Container
          className={mcn(styles.disclaimerContainer, useSmallerSizes && styles.disclaimerContainer__small)}
          pl={useSmallerSizes ? 8 : undefined}
          pr={useSmallerSizes ? 8 : undefined}
        >
          <Text variant={useSmallerSizes ? "small_2" : "body_2"} color="neutral_alt">
            <Trans tag="flights_index_usp3_disclaimer" />
          </Text>
        </Container>
      )}
    </>
  );
};

export default UspBlock;
