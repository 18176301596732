import React, { memo, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Stack, Tab, Text, Title, useTheme } from "@bookingcom/bui-react";
import { ThemeCarousel } from "./ThemeCarousel";
import Frame from "../Frame/Frame";
import Container from "../Container/Container";
import styles from "./styles.module.css";
import { Trans, t, useI18n } from "@bookingcom/lingojs-react";
import { FlightsThemesIcons } from "./IconsMapping";
import { getFlightsThemes } from "store/flightsThemes/selectors";
import { PriceDisclaimerDesktop } from "../PriceDisclaimer/PriceDisclaimer.desktop";
import flights_web_cat_theme_based_destinations_mdot from "utils/experiments/customer-aquisition/flights_web_cat_theme_based_destinations_mdot";
import flights_web_cat_theme_based_destinations_www from "utils/experiments/customer-aquisition/flights_web_cat_theme_based_destinations_www";

interface WrapperProps {
  isMobile: boolean;
  children: React.ReactNode;
}

const Wrapper = ({ isMobile, children }: WrapperProps): React.ReactElement => {
  const theme = useTheme();

  return isMobile ? (
    <Frame grow={1} pl={4} pr={4} mt={6} mb={6} attributes={{ style: { width: "100%" } }}>
      {children}
    </Frame>
  ) : (
    <Container style={{ margin: `${theme.units.spacing_6x} auto ${theme.units.spacing_8x}`, alignItems: "start" }}>
      {children}
    </Container>
  );
};

const ThemeBasedDestinationsContainer = memo(function _ThemeBasedDestinationsContainer({
  isMobile
}: {
  isMobile: boolean;
}) {
  const flightsThemes = useSelector(getFlightsThemes);
  const i18n = useI18n();
  const buiTheme = useTheme();

  /* eslint-disable @typescript-eslint/naming-convention */
  const { TriggerList = [], PanelList = [] } = useMemo(() => {
    if (!Array.isArray(flightsThemes) || flightsThemes.length === 0) {
      return {};
    }

    const TriggerListElements: JSX.Element[] = [];
    const PanelListElements: JSX.Element[] = [];

    flightsThemes.forEach(({ theme, routes }) => {
      if (!Array.isArray(routes) || routes.length === 0) {
        return;
      }

      TriggerListElements.push(
        <Tab.Trigger
          key={`theme_trigger-${theme.id}`}
          // @ts-expect-error: Element implicitly has an 'any' type. Fix the issue timely.
          icon={FlightsThemesIcons[theme.id]}
          text={theme.name}
          id={`theme_trigger-${theme.id}`}
          linkAttributes={{
            "aria-label": theme.name
          }}
        />
      );

      PanelListElements.push(
        <Tab.Panel
          key={`theme_panel-${theme.id}`}
          id={`theme_trigger-${theme.id}`}
          attributes={{
            "aria-label": theme.name
          }}
        >
          <ThemeCarousel
            themeId={theme.id}
            fromCity={flightsThemes[0].fromCity}
            destinations={routes}
            isMobile={isMobile}
          />
        </Tab.Panel>
      );
    });

    return { TriggerList: TriggerListElements, PanelList: PanelListElements };
  }, [flightsThemes, isMobile]);

  const fromCityName = flightsThemes?.[0]?.fromCity?.name;
  const isComponentEmpty = !flightsThemes || TriggerList.length === 0 || PanelList.length === 0 || !fromCityName;

  useEffect(() => {
    if (!isComponentEmpty) {
      isMobile
        ? flights_web_cat_theme_based_destinations_mdot.goals.themes_shown_1()
        : flights_web_cat_theme_based_destinations_www.goals.themes_shown_1();
    }
  }, [isComponentEmpty, isMobile]);

  const onTabClick = useCallback(() => {
    isMobile
      ? flights_web_cat_theme_based_destinations_mdot.goals.tab_click_2()
      : flights_web_cat_theme_based_destinations_www.goals.tab_click_2();
  }, [isMobile]);

  if (isComponentEmpty) {
    return null;
  }

  return (
    <Wrapper isMobile={isMobile}>
      <Stack
        direction="row"
        justifyContent="start"
        alignItems="end"
        wrap="wrap"
        attributes={{ style: { marginBlockEnd: buiTheme.units.spacing_4x } }}
      >
        <Title
          tagName="h2"
          variant="headline_2"
          title={i18n.trans(t("flights_index_destination_recommendation_header"))}
          subtitle={
            fromCityName ? (
              <Text variant="body_1" color="neutral_alt">
                <Trans
                  tag="flights_index_destination_recommendation_subheader"
                  variables={{
                    city_origin: flightsThemes[0].fromCity.name,
                    start_bold: "<0>",
                    end_bold: "</0>"
                  }}
                  components={[<Text key={0} tagName="span" variant="strong_1" />]}
                />
              </Text>
            ) : undefined
          }
          className={styles.title_wrapper}
        />
        {!isMobile && <PriceDisclaimerDesktop />}
      </Stack>

      <Stack direction="column" gap={isMobile ? 3 : undefined}>
        <Tab
          variant="rounded"
          defaultActiveTabId={`theme_trigger-${flightsThemes[0]?.theme?.id}`}
          moreLabel={i18n.trans(t("flights_index_destination_recommendation_dropdown_more"))}
          onTabChange={onTabClick}
        >
          {TriggerList && <Tab.TriggerList>{TriggerList}</Tab.TriggerList>}
          <div className={styles.tab_wrapper}>{PanelList}</div>
        </Tab>
        {isMobile && (
          <Text variant={"small_2"} color="neutral_alt">
            <Trans tag="flights_search_explore_price_accuracy_banner" />
          </Text>
        )}
      </Stack>
    </Wrapper>
  );
});

export default ThemeBasedDestinationsContainer;
