import { useCallback, useState } from "react";
import { URL_IATA_LIST_SEPARATOR } from "@flights/constants";
import useGlobalContext from "./useGlobalContext";
import { RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS } from "../constants";

const useSearchHistory = () => {
  const { searchHistory: globalSearchHistory } = useGlobalContext();
  const [searchHistory] = useState(globalSearchHistory || []);

  const generateSearchUrlFromSearchHistory = useCallback((data: SearchHistory, withFilters?: boolean) => {
    const params = new URLSearchParams({
      adults: data.adults || "",
      cabinClass: data.cabinClass || "",
      children: data.children || "",
      depart: data.depart || "",
      from: data.from || "",
      fromCountry: data.fromCountry || "",
      fromLocationName: data.fromLocationName || "",
      multiStopDates: data.multiStopDates || "",
      return: data.return || "",
      to: data.to || "",
      toCountry: data.toCountry || "",
      toLocationName: data.toLocationName || "",
      type: data.type || ""
    });
    if (withFilters) {
      RECENT_SEARCH_NEEDED_FILTER_PARAMS_KEYS.forEach((key: keyof SearchHistory) => {
        if (data[key] !== undefined && data[key] !== "") params.append(key, String(data[key]));
      });
    }
    const from = data.from?.replace(/,/g, URL_IATA_LIST_SEPARATOR);
    const to = data.to?.replace(/,/g, URL_IATA_LIST_SEPARATOR);
    return `/flights/${from}-${to}/?${params.toString()}`;
  }, []);

  return { searchHistory, generateSearchUrlFromSearchHistory };
};

export default useSearchHistory;
