import React from "react";

import { Stack, Text, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { UIFlightSegment } from "@flights/types";

interface CheckinHeaderProps {
  segment: UIFlightSegment;
}

export const CheckinHeader: React.FC<CheckinHeaderProps> = ({ segment }) => {
  const i18n = useI18n();

  return (
    <Stack gap={2}>
      <Title
        variant="headline_1"
        title={i18n.trans(
          t("flights_pb_checkin_header", {
            variables: {
              destination_city: segment.arrivalAirport.cityName || ""
            }
          })
        )}
      />
      <Text variant="body_1" color="neutral_alt">
        {i18n.trans(t("flights_pb_checkin_subhead"))}
      </Text>
    </Stack>
  );
};
