import { createTrackingUtils } from "utils/experiments";

const utils = createTrackingUtils({
  name: "flights_web_cat_theme_based_destinations_www",
  defaultStage: 1,
  stages: {
    main: 1
  },
  goals: {
    themes_shown_1: 1,
    tab_click_2: 2,
    item_click_3: 3,
    next_click_4: 4,
    trending_cities_or_popular_flights_item_click_5: 5
  },
  goalsWithValue: []
});

export default utils;
