import React, { Profiler } from "react";
import { useParams } from "react-router-dom";
import AppShell from "components/elements/AppShell";
import ConfirmationInnerLoader from "components/elements/ConfirmationInnerLoader";
import { assert } from "utils/assert";
import { PageHeader } from "./Home";
import useClientMetrics from "../hooks/useClientMetrics";
import { WebCheckinInnerV2 } from "../components/elements/WebCheckinInner";
import NoSSR from "../app/NoSSR";

const WebcheckinV2 = () => {
  const params = useParams<{ orderToken: string; segmentIndex: string }>();
  const { trackRenderTime } = useClientMetrics();
  const segmentIndex = Number(params?.segmentIndex);
  assert(params?.orderToken, "Missing orderToken parameter");
  assert(!isNaN(segmentIndex), "segmentIndex parameter must be a number");

  return (
    <Profiler id="screen" onRender={trackRenderTime}>
      <PageHeader />
      <AppShell noPadding noMainWrapper>
        <NoSSR>
          <ConfirmationInnerLoader token={params?.orderToken} cache={true}>
            <WebCheckinInnerV2 segmentIndex={segmentIndex} />
          </ConfirmationInnerLoader>
        </NoSSR>
      </AppShell>
    </Profiler>
  );
};

export default WebcheckinV2;
