import React from "react";
import { Box, useTheme, Text, Stack } from "@bookingcom/bui-react";
import { t, useI18n } from "@bookingcom/lingojs-react";
import { SearchboxController } from "@bookingcom/flights-searchbox";
import { SearchBoxHorizontalDefault } from "@bookingcom/flights-searchbox";
import Container from "../Container";
import { BLACK_FRIDAY_BG_COLOR, BLACK_FRIDAY_IMAGE_URL } from "../../../constants";
import NoJS from "screens/NoJS";
import useSearchbox from "hooks/useSearchbox";
import useLocaleContext from "hooks/useLocaleContext";
import useLandingPageEvents from "hooks/c360/useLandingPageEvents";
import { useTrackFlyAnywhere } from "hooks/useTrackFlyAnywhere";
import { trackWithDefaultStage } from "utils/etV2";
import styles from "./HomeSearchHeader.desktop.module.css";

const offset = 54; // Half the searcharea height
const offsetAddition = 14;

const HomeSearchHeader = () => {
  const theme = useTheme();
  const i18n = useI18n();
  const searchBoxProps = useSearchbox();
  const { isRTL } = useLocaleContext();
  const trackLandingPageEvents = useLandingPageEvents();
  const { trackFlyAnywhereWww } = useTrackFlyAnywhere();

  return (
    <>
      <Box
        attributes={{
          style: {
            width: "100%",
            backgroundColor: `${BLACK_FRIDAY_BG_COLOR}`,
            paddingTop: 0,
            paddingBottom: offset + offsetAddition + 5
          }
        }}
      >
        <Container style={{ padding: 0 }}>
          <NoJS />
          <div>
            <Stack gap={1} direction="row" alignItems="center" justifyContent="space-between">
              <Stack.Item grow>
                <Text variant="display_3" data-testid="home_header" tagName="h1" color="white" className={styles.title}>
                  {i18n.trans(t("index_hero_us_mile1_title_flights"))}
                </Text>
                <Text variant="featured_2" color="white" className={styles.subtitle}>
                  {i18n.trans(t("index_hero_row_mile1_subtitle_flights"))}
                </Text>
              </Stack.Item>
              <Stack.Item
                attributes={{
                  style: {
                    height: 302
                  }
                }}
              >
                <div
                  style={{
                    backgroundImage: `url(${BLACK_FRIDAY_IMAGE_URL})`,
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                    width: 283,
                    height: 280,
                    marginTop: 5
                  }}
                ></div>
              </Stack.Item>
            </Stack>
          </div>
        </Container>
      </Box>

      <Box
        attributes={{
          style: {
            width: "100%",
            paddingTop: 0,
            paddingBottom: theme.units.spacing_8x
          }
        }}
      >
        <Container
          style={{
            padding: 0,
            transform: `translateY(calc(-${offset + offsetAddition}px - ${theme.units.spacing_3x}))`,
            marginBottom: -offset
          }}
        >
          <SearchboxController
            i18n={i18n}
            {...searchBoxProps}
            hideShadow={true}
            isRTL={isRTL}
            isHeaderUpdateExp={!!trackWithDefaultStage("flights_web_cat_black_friday_hero", 1)}
            trackFlyAnywhereWww={trackFlyAnywhereWww}
            onSearch={(searchParams) => {
              trackLandingPageEvents("click", "searchbox");
              searchBoxProps.onSearch(searchParams);
            }}
          >
            {SearchBoxHorizontalDefault}
          </SearchboxController>
        </Container>
      </Box>
    </>
  );
};

export default HomeSearchHeader;
