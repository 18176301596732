import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Tab, Title } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";

import { getFlightsInterlinking } from "store/flightsInterlinking/selector";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";
import Frame from "components/elements/Frame";

import styles from "./FlightsInterlinkingDesktop.module.css";
import { useFlightsInterlinkingPanelList } from "components/elements/FlightsInterlinking/useFlightsInterlinkingPanelList";

const FlightsInterlinking = () => {
  const flightsInterlinking = useSelector(getFlightsInterlinking);
  const { panelsList, triggerList } = useFlightsInterlinkingPanelList(flightsInterlinking);
  const i18n = useI18n();
  const scrollRef = useRef<HTMLDivElement>(null);

  useTrackLandingPageViewport(scrollRef, "flights_interlinking");

  if (!flightsInterlinking) {
    return null;
  }

  const departureCountry = flightsInterlinking.departureCountry?.translatedName || "";

  return (
    <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4} elementRef={scrollRef}>
      <Title
        className={styles.title}
        title={i18n.trans(t("flights_interlinking_index_header", { variables: { country_name: departureCountry } }))}
        subtitle={i18n.trans(
          t("flights_interlinking_index_subheader", { variables: { country_name: departureCountry } })
        )}
        variant="headline_2"
        titleTagName="h2"
      />
      <Tab variant="rounded">
        <Tab.TriggerList>
          {triggerList.map((trigger) => (
            <Tab.Trigger key={trigger.id} {...trigger} />
          ))}
        </Tab.TriggerList>
        {panelsList.map((panel) => (
          <Tab.Panel key={panel.id} id={panel.id}>
            {panel.children}
          </Tab.Panel>
        ))}
      </Tab>
    </Frame>
  );
};

export default FlightsInterlinking;
