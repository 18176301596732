/* eslint-disable no-restricted-syntax -- this line was auto generated, hence fix the issue timely */
import React, { useRef } from "react";
import { useSelector } from "react-redux";
import { Title, Carousel, useTheme } from "@bookingcom/bui-react";
import { t } from "@bookingcom/lingojs-core";
import { useI18n } from "@bookingcom/lingojs-react";
import TrendingDestinationsItem from "./TrendingDestinationsItem";
import useGlobalContext from "hooks/useGlobalContext";
import Container from "../Container";
import Frame from "../Frame";
import styles from "./TrendingDestinations.module.css";
import { useTrackLandingPageViewport } from "hooks/c360/useLandingPageEvents";
import { getTrendingDestinations } from "store/trendingDestinations/selectors";
import { Theme } from "@bookingcom/bui-react/types/theme";

interface Props {
  isMobile: boolean;
}

interface WrapperProps {
  isMobile: boolean;
  children: React.ReactNode;
  theme: Theme;
}

const Wrapper = ({ isMobile, theme, children }: WrapperProps): React.ReactElement => {
  return isMobile ? (
    <Frame attributes={{ style: { width: "100%" } }} grow={1} p={4}>
      {children}
    </Frame>
  ) : (
    <Container style={{ margin: `${theme.units.spacing_8x} auto`, alignItems: "start" }}>{children}</Container>
  );
};

const TrendingDestinations: React.FunctionComponent<Props> = ({ isMobile }) => {
  const theme = useTheme();
  const i18n = useI18n();
  const trendingDestinations = useSelector(getTrendingDestinations);
  const { ipCountry } = useGlobalContext();
  const scrollRef = useRef<HTMLDivElement>(null);

  const subtitle = ipCountry
    ? i18n.trans(t("seo_flights_new_index_lp_trending_cities_subheader", { variables: { country_id: ipCountry } }))
    : i18n.trans(t("seo_flights_new_index_lp_trending_cities_fallback_subheader"));

  useTrackLandingPageViewport(scrollRef, "trending_cities");

  return trendingDestinations && trendingDestinations.length > 0 ? (
    <Wrapper isMobile={isMobile} theme={theme}>
      <Title
        className={styles.title}
        title={i18n.trans(t("seo_flights_new_index_lp_trending_cities_header"))}
        subtitle={subtitle}
        variant="headline_2"
        titleTagName="h2"
      />
      <div ref={scrollRef}>
        <Carousel
          attributes={{ id: "home_carouse_TRENDING" }}
          className={styles.carousel}
          size={isMobile ? "large" : "medium"}
          nextButtonAriaLabel={i18n.trans(t("a11y_flights_destination_carousel_next_button_aria_label"))}
          previousButtonAriaLabel={i18n.trans(t("a11y_flights_destination_carousel_previous_button_aria_label"))}
        >
          {trendingDestinations.map((destination, index) => (
            <TrendingDestinationsItem
              destination={destination}
              key={`des-${index}`}
              index={index}
              component="trending_cities"
            />
          ))}
        </Carousel>
      </div>
    </Wrapper>
  ) : null;
};
export default TrendingDestinations;
